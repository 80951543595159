import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../layouts"
import Seo from "../components/seo"
import {
  Section,
  SectionTitle,
  SectionDescription,
  Container,
  BreadCrumb,
  MarkdownContent,
  SectionInnerWhite,
} from "../components/Section"
import ProductCard from "../components/ProductCard"
import BreakpointUp from "../components/Media/BreakpointUp"
import ColorBoards from "../components/ColorBoards"
import Badge from "../components/Badge"
import PrimaryButton from "../components/Button/PrimaryButton"
import DefaultButton from "../components/Button/DefaultButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import WidthIcon from "../components/Icons/WidthIcon"
import LengthIcon from "../components/Icons/LenghtIcon"
import HeightIcon from "../components/Icons/HeightIcon"
import BreakpointDown from "../components/Media/BreakpointDown"
import RequestModal from "../components/RequestModal"
import FreeQuoteForm from "../components/FreeQuoteForm"

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
`

const ProductRow = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  ${BreakpointUp.xl` 
    flex-wrap:nowrap;
  `}
  ${BreakpointUp.xxl`
    margin-left:-30px;
    margin-right:-30px;
  `}
`
const ProductColLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md` 
    flex: 0 0 25%;
    max-width: 25%;
  `}
  ${BreakpointUp.xxl`
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding:0 30px;
  `}
`
const ProductColCenter = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md` 
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
  ${BreakpointUp.xxl`
    padding:0 30px;
  `}
`
const ProductColRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md` 
    margin-left:25%;
  `}
  ${BreakpointUp.xl`    
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin-left:0;
  `}
  ${BreakpointUp.xxl`
    padding:0 30px;
  `}
`
const ProductassitsWrapper = styled.div`
  display: block;
  margin-bottom: 30px;
  ${BreakpointUp.md`
    margin-bottom:0;
  `}
`
const Productassits = styled.div`
  & + & {
    margin-top: 30px;
    ${BreakpointUp.xxl`
      margin-top:50px;
    `}
  }
  button {
    width: 100%;
  }
  .btn {
    display: block;
  }
`
const ProductassitsTitle = styled.div`
  margin-bottom: 5px;
  text-align: center;
`

const Measurement = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0px 25px 65px rgba(3, 51, 102, 0.2);
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 15px;
  border: 1px solid #cbd7e0;
  ${BreakpointUp.lg`
    flex-direction:column;
  `}
`
const MeasurementItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding: 15px 5px;
  justify-content: center;
  ${BreakpointUp.md`
    padding:25px 10px;
  `}
  ${BreakpointUp.xl`    
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  `}
  &+& {
    border-left: 1px solid #cad7e0;
    border-top: none;
    ${BreakpointUp.xl`    
      border-top:1px solid #CAD7E0;
      border-left:none;
    `}
  }
  .icon {
    ${BreakpointDown.sm`
     max-width:46px; 
     svg{ max-width:100%;}
    `}
    + .text {
      margin-left: 10px;
      ${BreakpointDown.sm`
        margin-left:4px;
        flex-direction:column;
      `}
    }
    svg {
      g {
        stroke: #999999;
      }
      > path {
        stroke: #d51333;
      }
    }
  }
  .text {
    text-align: left;
    line-height: 20px;
    small,
    strong {
      display: block;
      font-weight: 700;
    }
    small {
      font-size: 12px;
      line-height: 20px;
      color: #999999;
    }
    strong {
      font-size: 12px;
      line-height: 20px;
      color: #d51333;
    }
  }
`
const ProductTitle = styled.h1`
  text-align: center;
  margin: 0 0 10px;
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #033366;
    }
  }
`

const ProductImage = styled.div`
  text-align: center;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 40px;
  ${BreakpointUp.xxl`
    margin-bottom:60px;
  `}
`
const ProductNote = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const ProductPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px 10px -7px;
  justify-content: center;
  ${BreakpointDown.sm`
    margin:0 -7px 0 -7px;
  `}
  strong {
    color: #d51333;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    letter-spacing: 0.3px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 26px;
    ${BreakpointUp.md`
      font-size: 20px;
      line-height: 28px;
    `}
    ${BreakpointUp.lg`
      font-size: 24px;
      line-height: 30px;
    `}
    ${BreakpointUp.xxl`
      font-size: 28px;
      line-height: 36px;
    `}
  }
  span {
    color: #4c4f52;
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    font-size: 16px;
    ${BreakpointUp.md`
      font-size:18px;
    `}
    + strong {
      margin-left: 5px;
    }
  }
`
const StartPrice = styled.div`
  position: relative;
  padding: 0 7px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  ${BreakpointDown.sm`
    padding:0 2px;
  `}
`
// const MonthlyPrice = styled.div`
//   position: relative;
//   padding: 0 7px;
//   flex: 0 0 auto;
//   width: auto;
//   max-width: 100%;
//   ${BreakpointDown.sm`
//     padding:0 2px;
//   `}
// `
const ProductBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -4px 40px -4px;
  .badge {
    margin: 0 4px;
    ${BreakpointUp.sm`
      max-width:230px;
      padding: 16px 20px;
      width:100%;
    `}
    > .text {
      margin: 0 auto;
    }
  }
`

const ProductFeatures = styled.div``
const ProductFeatureContent = styled.div`
  + div {
    margin-top: 40px;
    ${BreakpointUp.xxl`
      margin-top:80px;
    `}
  }
`

const ProductFeatureTitle = styled.div`
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  margin: 0 0 10px;
  letter-spacing: 0.3px;
  font-size: 18px;
  line-height: 26px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (min-width: 1600px) {
    font-size: 28px;
    line-height: 36px;
  }
`

const GetQuoteContainer = styled.div`
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
`
const GetQuoteRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  ${BreakpointUp.lg`
    margin:0 -10px 30px -10px;  
  `}
  ${BreakpointUp.xl`
    margin:0 -30px 30px -30px;      
  `}
`
const GetQuoteCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  text-align: center;
  height: 100%;
  ${BreakpointUp.lg`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;  
  `}
  ${BreakpointUp.xl`
    padding:0 30px;
  `}
  p {
    margin-bottom: 0;
  }
`
const GetQuoteAuto = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  text-align: center;
  ${BreakpointUp.lg`
    text-align:left;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  `}
  ${BreakpointUp.xl`
    padding:0 30px;
  `}
`
const OrSepContainer = styled.div`
  position: relative;
  height: 100%;
  margin: 30px 0 0;
  ${BreakpointUp.lg`
    margin:0;
  `}
  &:after {
    content: "";
    display: block;
    position: absolute;

    background-image: linear-gradient(
      to right,
      rgba(3, 51, 102, 1) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: center top;
    background-size: 9px 1px;
    background-repeat: repeat-x;
    width: 100%;
    height: 2px;
    left: auto;
    top: 0;
    bottom: 0;

    ${BreakpointUp.lg`
      bottom: 0px;
      left: 50%;
      background-image: linear-gradient(to top, rgba(3, 51, 102, 1) 50%, rgba(255, 255, 255, 0) 0%);
      background-position: center top;
      background-size: 1px 9px;
      background-repeat: repeat-y;
      width: 2px;
      height: 100%;
      transform: translateX(-50%);
    `}
  }
`
const OrSep = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 0 auto;

  top: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BreakpointUp.lg`
    top:20px;
    left:auto;
  `}
`
const GetQuoteDesc = styled.div`
  margin-bottom: 30px;
  p {
    font-size: 16px;
    line-height: 28px;
  }
`
const GetQuoteMedia = styled.div`
  margin-bottom: 30px;
`
const GetQuoteForm = styled.div``
const ExploreMore = styled.div`
  display: block;
`
const GetQuoteTitle = styled.div`
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (min-width: 1600px) {
    font-size: 32px;
    line-height: 42px;
  }
  span {
    color: #d51333;
  }
`

const ProductPageTemplate = ({ location, data }) => {
  const productData = data.contentfulProduct
  const similarProducts = data.allContentfulProduct.edges
  const productImage = getImage(productData.image)
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  let colors = []
  data.allContentfulProductColor.edges.map(item => colors.push(item.node))

  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }
  return (
    <Layout isHeader={true} location={location}>
      <Seo title={productData.name} />
      <BreadCrumb>
        <Container maxWidth="100%" pl="3%" pr="3%">
          <Link to="/">Home</Link>
          <Link to={productData.category.url}>{productData.category.name}</Link>
          <span>{productData.name}</span>
        </Container>
      </BreadCrumb>
      <Section
        xpt="190px"
        mpt="150px"
        pt="140px"
        xpb="90px"
        mpb="60px"
        pb="40px"
        bgColor="#F0F8FF"
      >
        <Container maxWidth="1800px" pl="3%" pr="3%">
          <ProductRow>
            <ProductColLeft>
              <ProductassitsWrapper className="isSticky">
                <Productassits>
                  <ProductassitsTitle>
                    Are You Ready To Get Started?
                  </ProductassitsTitle>
                  <button
                    type="button"
                    aria-label="button"
                    onClick={HandleModalOpen}
                  >
                    <DefaultButton text="Request a Quote" />
                  </button>
                </Productassits>
                <Productassits>
                  <ProductassitsTitle>
                    Design Your Dream Building
                  </ProductassitsTitle>
                  <a
                    href="https://libertymetalstructures.sensei3d.com/"
                    target="_blank"
                    without
                    rel="noreferrer"
                    aria-label="3D Designer"
                  >
                    <PrimaryButton text="3D Designer" />
                  </a>
                </Productassits>
                <Productassits>
                  <ProductassitsTitle>Call Us Today</ProductassitsTitle>
                  <a href="tel:8004407309" aria-label="Phone">
                    <PrimaryButton
                      icon={<PhoneIcon />}
                      textAfter="(800) 440-7309"
                    ></PrimaryButton>
                  </a>
                </Productassits>
              </ProductassitsWrapper>
            </ProductColLeft>
            <ProductColCenter>
              <SectionInnerWhite>
                <MarkdownContent maxWidth="936px">
                  <ProductTitle className="card-title">
                    {productData.name}
                  </ProductTitle>
                  <ProductPrice>
                    <StartPrice>
                      <span>Starting Price :</span>
                      <strong>${productData.price}</strong>
                    </StartPrice>
                    {/* <MonthlyPrice>
                      <span>Monthly Payment :</span>
                      <strong>$110.00</strong>
                    </MonthlyPrice> */}
                  </ProductPrice>
                  <ProductNote>
                  *Prices Vary With States And Certification Requirements
                  </ProductNote>
                  <ProductBadge>
                    {productData.availableFinancing && (
                      <Badge text="FINANCING" />
                    )}
                    {productData.availableRto && <Badge text="RTO" />}
                  </ProductBadge>
                  <ProductImage>
                    <GatsbyImage image={productImage} alt={productData.name} />
                  </ProductImage>
                  <ProductFeatures>
                    <ProductFeatureContent>
                      <ProductFeatureTitle>Description</ProductFeatureTitle>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            productData.description.childMarkdownRemark.html,
                        }}
                      />
                    </ProductFeatureContent>
                    <ProductFeatureContent>
                      <ProductFeatureTitle>
                        Metal Building Color options
                      </ProductFeatureTitle>
                      <ColorBoards colors={colors} setColor={() => {}} />
                    </ProductFeatureContent>
                  </ProductFeatures>
                </MarkdownContent>
              </SectionInnerWhite>
            </ProductColCenter>
            <ProductColRight>
              <ProductassitsWrapper className="isSticky">
                <Productassits>
                  <ProductassitsTitle>Building Dimensions</ProductassitsTitle>
                  <Measurement>
                    <MeasurementItem>
                      <span className="icon">
                        <WidthIcon />
                      </span>
                      <span className="text">
                        <small>Width</small>
                        <strong>{productData.width}</strong>
                      </span>
                    </MeasurementItem>
                    <MeasurementItem>
                      <span className="icon">
                        <LengthIcon />
                      </span>
                      <span className="text">
                        <small>Length</small>
                        <strong>{productData.length}</strong>
                      </span>
                    </MeasurementItem>
                    <MeasurementItem>
                      <span className="icon">
                        <HeightIcon />
                      </span>
                      <span className="text">
                        <small>Height</small>
                        <strong>{productData.height}</strong>
                      </span>
                    </MeasurementItem>
                  </Measurement>
                </Productassits>
              </ProductassitsWrapper>
            </ProductColRight>
          </ProductRow>
        </Container>
      </Section>
      <Section
        xpt="100px"
        mpt="60px"
        pt="30px"
        xpb="100px"
        mpb="60px"
        pb="30px"
        bgColor="#fff"
        boxShadow="0 25px 55px rgba(3,51,102,0.20)"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <GetQuoteContainer>
            <GetQuoteRow>
              <GetQuoteCol>
                <GetQuoteTitle>
                  Get a <span>Free Quote</span> for Your Dream Metal Building
                </GetQuoteTitle>
                <GetQuoteDesc>
                  <p>
                    Request a detailed quote for your next metal building
                    project. Our experts will work with you to plan and price
                    your next steel metal building.
                  </p>
                </GetQuoteDesc>
                <GetQuoteForm>
                  <FreeQuoteForm location={location} />
                </GetQuoteForm>
              </GetQuoteCol>
              <GetQuoteAuto>
                <OrSepContainer>
                  <OrSep>OR</OrSep>
                </OrSepContainer>
              </GetQuoteAuto>
              <GetQuoteCol>
                <GetQuoteTitle>
                  Design Your Dream Metal Building With Our{" "}
                  <span>3D Building Estimator</span>
                </GetQuoteTitle>
                <GetQuoteDesc>
                  <p>
                    Design and get a quote on a metal building with our 3D
                    Design Tool. Choose the building size, door and window
                    placements, colors, and more. Once you have saved your
                    design, just get a free quote in real-time.
                  </p>
                </GetQuoteDesc>
                <GetQuoteMedia>
                  <StaticImage
                    src="../images/building-design.png"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="building-design"
                  />
                </GetQuoteMedia>
                <ExploreMore>
                  <a
                    className="btn-block"
                    href="https://libertymetalstructures.sensei3d.com/"
                    target="_blank"
                    without
                    rel="noreferrer"
                    aria-label="3D Designer"
                  >
                    <PrimaryButton text="Design Your Own Building" size="lg" />
                  </a>
                </ExploreMore>
              </GetQuoteCol>
            </GetQuoteRow>
          </GetQuoteContainer>
        </Container>
      </Section>
      <Section
        xpt="100px"
        mpt="60px"
        pt="40px"
        xpb="30px"
        mpb="10px"
        pb="0"
        bgColor="#F0F8FF"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionTitle mb="10px">Similar Products</SectionTitle>
          <SectionDescription maxWidth="1496px" mb="40px">
            <p>
              See our wide selection of similar metal buildings with the best
              prices we offer.
            </p>
          </SectionDescription>
          <SectionGrid>
            {similarProducts.map((item, i) => {
              if (i < 3) {
                return (
                  <SectionGridCol key={i}>
                    <ProductCard
                      data={item.node}
                      openQuoteModal={HandleModalOpen}
                    />
                  </SectionGridCol>
                )
              }
              return <></>
            })}
          </SectionGrid>
        </Container>
        <RequestModal
          location={location}
          isVisible={isQuoteVisible}
          onClose={HandleModalClose}
        />
      </Section>
    </Layout>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query ProductPageTemplateQuery($id: String!, $category: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      name
      description {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
      }
      subCategory {
        name
      }
      sku
      roofType {
        name
      }
      width
      length
      height
      price
      availableFinancing
      availableRto
      image {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
      warranty {
        warranty
      }
      otherSpec {
        otherSpec
      }
    }
    allContentfulProductColor {
      edges {
        node {
          name
          colorCode
          sampleImage {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
    allContentfulProduct(filter: { category: { name: { eq: $category } } }) {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
  }
`
